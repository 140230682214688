import { createSlice } from '@reduxjs/toolkit';
import { step1InitialValues } from '../pages/Guest/Register/Step1/step1Inputs';


const initialRegisterState = {
    newUserData: !!JSON.parse(localStorage.getItem("newUserData")) ?
        JSON.parse(localStorage.getItem("newUserData")) :
        step1InitialValues,
}


const registerSlice = createSlice({
    name: 'register',
    initialState: initialRegisterState,
    reducers: {
        updateData(state, action)
        {
            state.newUserData = action.payload;
            localStorage.setItem("newUserData", JSON.stringify(action.payload))
        },
    }
})


export const registerActions = registerSlice.actions

export default registerSlice.reducer;