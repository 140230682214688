import { createSlice } from '@reduxjs/toolkit';
import { calcTokenExpireDate } from '../helpers/calcTokenExpireDate';
import { removeNull } from '../helpers/removeNull';


const initialAuthState = {
    token: localStorage.getItem("token"),
    userData: JSON.parse(localStorage.getItem("userData")),
    isLoggedIn: !!localStorage.getItem("token"),
    tokenExpireDate: JSON.parse(localStorage.getItem("tokenExpireDate")) || "",
    isTokenExpired: !!JSON.parse(localStorage.getItem("isTokenExpired")),
    type: JSON.parse(localStorage.getItem("type")) || "",
    guestTabsIsOpen: JSON.parse(localStorage.getItem("guestTabsIsOpen")) || false,
}

const logOutHandler = (state) =>
{
    state.token = null;
    state.userData = null;
    state.isLoggedIn = false;
    localStorage.clear()
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login(state, action)
        {
            state.token = action.payload.token.access_token;
            action.payload.ID_number = action.payload.id_number
            state.userData = removeNull(action.payload);
            state.isLoggedIn = true;
            state.type = action.payload.type;
            const newTokenExpireDate = calcTokenExpireDate(action.payload.token.expires_in)
            state.tokenExpireDate = newTokenExpireDate

            localStorage.setItem("tokenExpireDate", JSON.stringify(newTokenExpireDate));
            localStorage.setItem("token", action.payload.token.access_token)
            localStorage.setItem("userData", JSON.stringify(action.payload))
            localStorage.setItem("type", JSON.stringify(action.payload.type))

            //delete register data
            localStorage.removeItem("newUserData")
        },
        logout(state)
        {
            logOutHandler(state)
        },
        updateUserData(state, action)
        {
            state.userData = { ...state.userData, ...action.payload };
            localStorage.setItem("userData", JSON.stringify(state.userData))
        },
        checkTokenExpire(state)
        {
            if (!state.isLoggedIn ||
                (!!state.tokenExpireDate && (new Date(state.tokenExpireDate) > new Date()))
            )
            {
                state.isTokenExpired = false;
                localStorage.setItem("isTokenExpired", JSON.stringify(false))
            } else
            {
                logOutHandler(state);
                state.isTokenExpired = true;
            }

        },
        updateGuestTabsIsOpen(state, action)
        {
            state.guestTabsIsOpen = action.payload;
            localStorage.setItem("guestTabsIsOpen", JSON.stringify(state.guestTabsIsOpen))
        }
    }
})


export const authActions = authSlice.actions

export default authSlice.reducer;