import { createSlice } from '@reduxjs/toolkit';

const initialIndividualsState = {
    individuals: !!JSON.parse(localStorage.getItem("individuals")) ?
        JSON.parse(localStorage.getItem("individuals")) : {},
    eventName: !!JSON.parse(localStorage.getItem("eventName")) ?
        JSON.parse(localStorage.getItem("individuals")) : ""
};

const individualsSlice = createSlice({
    name: "individuals",
    initialState: initialIndividualsState,
    reducers: {
        updateData(state, action)
        {
            state.individuals[action.payload.individualId] = action.payload.data;
            localStorage.setItem(
                "individuals",
                JSON.stringify(state.individuals)
            );
        },
        deleteData(state)
        {
            state.individuals = {};
            localStorage.removeItem("individuals");
        },
        removePerson(state, action)
        {
            delete state.individuals[action.payload]
            localStorage.setItem(
                "individuals",
                JSON.stringify(state.individuals)
            );
        },
        updateEventName(state,action){
            state.eventName= action.payload;
            localStorage.setItem(
                "eventName",
                JSON.stringify(state.eventName)
            );
        }
    },
});

export const individualsActions = individualsSlice.actions

export default individualsSlice.reducer;