import { createSlice } from '@reduxjs/toolkit';


const initialEventState = {
    event: !!JSON.parse(localStorage.getItem("event")) ?
        JSON.parse(localStorage.getItem("event")) :
        {},
}


const eventSlice = createSlice({
    name: 'event',
    initialState: initialEventState,
    reducers: {
        addData(state, action)
        {
            state.event = action.payload;
            localStorage.setItem("event", JSON.stringify(action.payload))
        },
        clearData(state)
        {
            state.event = {};
            localStorage.removeItem("event")
        },
    }
})


export const eventActions = eventSlice.actions

export default eventSlice.reducer;