import { createSlice } from '@reduxjs/toolkit';


const initialOperationState = {
    operation: !!JSON.parse(localStorage.getItem("operation")) ?
        JSON.parse(localStorage.getItem("operation")) :
        {},
}


const operationSlice = createSlice({
    name: 'operation',
    initialState: initialOperationState,
    reducers: {
        addData(state, action)
        {
            state.operation = action.payload;
            localStorage.setItem("operation", JSON.stringify(action.payload))
        },
        clearData(state)
        {
            state.operation = {};
            localStorage.removeItem("operation")
        },
    }
})


export const operationActions = operationSlice.actions

export default operationSlice.reducer;