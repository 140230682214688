
import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth-slice';
import registerReducer from './register-slice';
import individualsReducer from './individuals-slice';
import operationReducer from './operation-slice';
import eventReducer from './event-slice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        register: registerReducer,
        individuals: individualsReducer,
        operation: operationReducer,
        event: eventReducer,
    }
});

export default store;