const graduationYearOptions = [
    { key: "يرجي اختيار سنة التخرج", value: "none" },
];
const currentYear = new Date().getFullYear();
const startYear = 2020;

for (let year = startYear; year <= currentYear; year++)
{
    graduationYearOptions.push({ key: year, value: year });
}

export const governoratesOptions = [
    { key: "يرجي اختيار المحافظة", value: "none" },
    { "value": "Alexandria", "key": "Alexandria" },
    { "value": "Aswan", "key": "Aswan" },
    { "value": "Asyut", "key": "Asyut" },
    { "value": "Beheira", "key": "Beheira" },
    { "value": "Beni Suef", "key": "Beni Suef" },
    { "value": "Cairo", "key": "Cairo" },
    { "value": "Dakahlia", "key": "Dakahlia" },
    { "value": "Damietta", "key": "Damietta" },
    { "value": "Fayoum", "key": "Fayoum" },
    { "value": "Gharbia", "key": "Gharbia" },
    { "value": "Giza", "key": "Giza" },
    { "value": "Ismailia", "key": "Ismailia" },
    { "value": "Kafr el-Sheikh", "key": "Kafr el-Sheikh" },
    { "value": "Luxor", "key": "Luxor" },
    { "value": "Matrouh", "key": "Matrouh" },
    { "value": "Minya", "key": "Minya" },
    { "value": "Monufia", "key": "Monufia" },
    { "value": "New Valley", "key": "New Valley" },
    { "value": "North Sinai", "key": "North Sinai" },
    { "value": "Port Said", "key": "Port Said" },
    { "value": "Qalyubia", "key": "Qalyubia" },
    { "value": "Red Sea", "key": "Red Sea" },
    { "value": "Sharqia", "key": "Sharqia" },
    { "value": "Sohag", "key": "Sohag" },
    { "value": "South Sinai", "key": "South Sinai" },
    { "value": "Suez", "key": "Suez" },
    { "value": "Helwan", "key": "Helwan" },
    { "value": "6th of October", "key": "6th of October" },
];
governoratesOptions.sort();

export const facultiesOptions = [
    { key: "يرجي اختيار الكلية", value: "none" },
    { value: "كلية التربية", key: "كلية التربية" },
    { value: " كلية الزراعة", key: " كلية الزراعة" },
    { value: "كلية العلوم", key: "كلية العلوم" },
    { value: "كلية التربية للطفولة المبكرة", key: "كلية التربية للطفولة المبكرة" },
    { value: "كلية التمريض", key: "كلية التمريض" },
    { value: "كلية الصيدلة", key: "كلية الصيدلة" },
    { value: "كلية طب الأسنان", key: "كلية طب الأسنان" },
    { value: "معهد الدراسات العليا والبحوث البيئية", key: "معهد الدراسات العليا والبحوث البيئية" },
    { value: "كلية التربية النوعية بالنوبارية", key: "كلية التربية النوعية بالنوبارية" },
    { value: "كلية الحاسبات والمعلومات", key: "كلية الحاسبات والمعلومات" },
    { value: "كلية الهندسة", key: "كلية الهندسة" },
    { value: "تعليم مدمج", key: "تعليم مدمج" },
];
facultiesOptions.sort();

export const step1DataInputs = [
    {
        control: "input",
        type: "text",
        name: "name",
        label: "الاسم",
        placeholder: "يرجي ادخال الاسم كما موضح بالبطاقة الشخصية",
    },
    {
        control: "input",
        type: "number",
        name: "ID_number",
        label: "الرقم القومي",
        placeholder: "يرجي ادخال الرقم القومي كما موضح بالبطاقة الشخصية",
    },
    {
        control: "input",
        type: "text",
        name: "address",
        label: "العنوان",
        placeholder: "يرجي ادخال العنوان كما موضح بالبطاقة الشخصية",
    },
    {
        control: "select",
        name: "governorate",
        label: "المحافظة",
        options: governoratesOptions,
        size: 12,
        first: true
    },
    {
        control: "input",
        type: "text",
        name: "city",
        label: "المركز",
        placeholder: "يرجي ادخال المركز في حالة عدم الوجود يكتب \"بدون\"",
        size: 12
    },
    {
        control: "input",
        type: "text",
        name: "village",
        label: "القرية",
        placeholder: "يرجي ادخال القرية في حالة عدم الوجود يكتب \"بدون\"",
        size: 12
    },
    {
        control: "input",
        type: "text",
        name: "phone",
        label: "رقم الهاتف",
        placeholder: "يرجي ادخال رقم الهاتف",
        size: 5,
        canEdit: true
    },
    {
        control: "input",
        type: "text",
        name: "email",
        label: "البريد الالكتروني",
        placeholder: "يرجي ادخال البريد الالكتروني",
        size: 7,
        canEdit:true
    },
    {
        control: "select",
        name: "university",
        label: "الجامعة",
        options:
            [
                { key: "يرجي اختيار الجامعة", value: "none" },
                { key: "دمنهور", value: "دمنهور" }
            ],
        size: 12
    },
    {
        control: "select",
        name: "faculty",
        label: "الكلية",
        options: facultiesOptions,
        size: 12,
        canEdit: true
    },
];

export const statusOfUser = {
    control: "select",
    name: "type",
    label: "الحالة",
    options:
        [
            { key: "يرجي اختيار الحالة", value: "none" },
            { key: "طالب", value: 2 },
            { key: "خريج", value: 3 },
            { key: "عضو هيئة تدريس", value: 4 },
            { key: "موظفين", value: 5 },
        ],
};

export const level = {
    control: "select",
    name: "level",
    label: "الفرقة الدراسية",
    options:
        [
            { key: "يرجي اختيار الفرقة الدراسية", value: "none" },
            { key: "اعدادي", value: "اعدادي" },
            { key: "الاولي", value: "الاولي" },
            { key: "الثانية", value: "الثانية" },
            { key: "الثالثة", value: "الثالثة" },
            { key: "الرابعة", value: "الرابعة" },
            { key: "الخامسة", value: "الخامسة" },
            { key: "السادسة", value: "السادسة" },
        ],
    canEdit: true
};

export const graduationYear = {
    control: "select",
    name: "graduation_year",
    label: "سنة التخرج",
    options: graduationYearOptions,
};

export const doctorType = {
    control: "select",
    name: "title",
    label: "الصفة العلمية",
    options:
        [
            { key: "يرجي اختيار  الحالة", value: "none" },
            { key: "معيد", value: "معيد" },
            { key: "مدرس مساعد", value: "مدرس مساعد" },
            { key: "مدرس", value: "مدرس" },
            { key: "أستاذ مساعد دكتور", value: "أستاذ مساعد دكتور" },
            { key: "أستاذ دكتور", value: "أستاذ دكتور" },
        ],
};

export const employeesType = {
    control: "input",
    type: "text",
    name: "organization",
    label: "الهيئة",
    placeholder: "يرجي ادخال الهيئة",

};

export const extraInputsMap = {
    2: level,
    3: graduationYear,
    4: doctorType,
    5: employeesType,
    "": {}
}

export const step1InitialValues = {
    name: "",
    ID_number: "",
    address: "",
    governorate: "none",
    city: "",
    village: "",
    phone: "",
    email: "",
    university: "none",
    faculty: "none",
    graduation_year: "none",
    title: "none",
    level: "none",
    organization: "",
    type: "none",
}