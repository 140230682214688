import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

import './App.css';
import Footer from './components/ui/Footer';
import GlobalCssPriority from './components/ui/GlobalCssPriority';
import LoadingFullScreen from './components/ui/LoadingFullScreen';

const GuestIndex = lazy(() => import('./pages/Guest/GuestIndex'))
const UserIndex = lazy(() => import('./pages/User/UserIndex'))
const SuperAdminIndex = lazy(() => import('./pages/SuperAdmin/SuperAdminIndex'))
const AdminIndex = lazy(() => import('./pages/Admin/AdminIndex'))

function App()
{
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const type = useSelector((state) => state.auth.type)
  return (
    <GlobalCssPriority>
      <Suspense fallback={<LoadingFullScreen isLoading={true} />}>
        {!!isLoggedIn ? (
          Number(type) === 0 ?
            (<SuperAdminIndex />) :
            Number(type) === 1 ?
              <AdminIndex /> :
              <UserIndex />
        ) :
          (
            <GuestIndex />
          )}
        <Footer />
      </Suspense>
    </GlobalCssPriority>
  );
}

export default App;
