import classes from './style/Footer.module.css'

const Footer = () =>
{
    return (
        <div
            className={classes.container}
        >
            <p>
                {new Date().getFullYear()}
                @ واعي   
            </p>
        </div>
    )
}

export default Footer