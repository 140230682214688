export const removeNull = (obj) =>
{
    for (let key in obj)
    {
        if (key!=="type" && !obj[key])
        {
            delete obj[key];
        }
    }
    return obj;
}