import { CircularProgress } from '@mui/material'
import classes from './style/LoadingFullScreen.module.css'

const LoadingFullScreen = () =>
{
    return (
        <div
            className={classes.container}
        >
            <CircularProgress
                size={50}
            />
        </div>
    )
}

export default LoadingFullScreen